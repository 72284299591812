@charset "UTF-8";

body {
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Nunito Sans", sans-serif;
}

@font-face {
  font-family: "Open Sans";
  src: url("fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Semibold";
  src: url("fonts/Open_Sans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Semibold";
  src: url("fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: url("fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Light";
  src: url("fonts/Nunito/Nunito-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Bold";
  src: url("fonts/Nunito/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Black";
  src: url("fonts/Nunito/Nunito-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito ExtraBold";
  src: url("fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Semibold";
  src: url("fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Italic";
  src: url("fonts/Nunito/Nunito-Italic.ttf") format("truetype");
}


@font-face {
  font-family: "Nunito Sans Italic";
  src: url("fonts/Nunito_Sans/NunitoSans_10pt-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Sans";
  src: url("fonts/Nunito_Sans/NunitoSans_10pt-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Sans Light";
  src: url("fonts/Nunito_Sans/NunitoSans_10pt-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Sans Bold";
  src: url("fonts/Nunito_Sans/NunitoSans_10pt-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Sans SemiBold";
  src: url("fonts/Nunito_Sans/NunitoSans_10pt-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Sans Black";
  src: url("fonts/Nunito_Sans/NunitoSans_10pt-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito Sans ExtraBold";
  src: url("fonts/Nunito_Sans/NunitoSans_10pt-ExtraBold.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
